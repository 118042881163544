import React from "react";
import {
  ContainerAbout,
  AboutSections,
  AboutTitle,
  AboutGrid,
  ImgProfile,
  DataGrid,
  TextDescriptions,
  ButtonDoawnload,
  Link,
} from "./About.styled";
import Me from "../../assets/avatar-2.svg";
import Cv from "../../doc/Ourradour Marc-Antoine FullStack Blockchain Developer.pdf";

const About = (props) => {
  return (
    <ContainerAbout ref={props.refProp}>
      <AboutSections>
        <AboutTitle>About Me</AboutTitle>

        <AboutGrid>
          <ImgProfile>
            <img style={{ height: "10rem" }} src={Me} alt="draw face"></img>
          </ImgProfile>
          <DataGrid>
            <TextDescriptions>
              I entered the world of blockchain in 2018 after purchasing a Flux
              masternode. Interested, I dug into a lot of notions related to
              this new technology. Quicly, I decided to take my skills further
              by specializing in the blockchain focused Fintech major. It didn't
              take much more than a hackathon win at the summit of Paris
              Blockchain Week to decide to join professionally the blockchain
              industry.
              <br />
              <br />
              I've had the chance to implement a marketplace, a mint page & a
              defi Protocol. I'm passionate about this technology and what I do.
              I always want to go further and improve my skills.
              <br />
              <br />
              👨‍🎓 &nbsp;&nbsp;
              <Link
                href="https://www.esilv.fr/en/programmes/master-degree-engineering/majors/fintech/"
                target="_blank"
              >
                ESILV
              </Link>{" "}
              graduate engineer.
            </TextDescriptions>
            <a href={Cv} download>
              <ButtonDoawnload>Download cv</ButtonDoawnload>
            </a>
          </DataGrid>
        </AboutGrid>
      </AboutSections>
    </ContainerAbout>
  );
};

export default About;
