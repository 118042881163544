const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2021 - 2023",
    title: "Major FINTECH at ESILV",
    desc: "Blockchain programming, Quantum Computing, Virtualization, Cryptocurrency market, Cryptography, Web/Mobile Development, Machine Learning, Datascience and Statistics courses. ",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2019 - 2021",
    title: "Engineering school at Esilv",
    desc: "Datascience,Data structure, POO, IA, Statistics Physics courses, Introduction to financial markets. Weeks training of Soft Skills, entrepreneurial projects.",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-graduation",
    year: "2017 - 2019",
    title: "Preparatory class PCSI/PC",
    desc: "Maths/Physics and Chemistry courses. Participation to Chemistry Olympiad. TIPE project which takes place part of a thesis on hydrophobic surfaces at the Institut Pierre Gilles-de-Gennes (liquid infused surfaces).",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "september 2023 - ",
    title: "Backend developer ",
    desc: "Power blockchain services, ensuring its security, efficiency, and scalability.",
    competences:
      "Next/Nextra · Thegraph Subgraphs mesh/substreams · Express · Telegram bot · grafana K6",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "september 2022 - august 2023 ",
    title: "Web3 front end developer at Pyratzlabs",
    desc: "Ensure performance, maintainability and realiability of dapp.",
    competences:
      "Next.js · Git · MagicLink · GraphQL(Hasura) · styled-components · Tailwind CSS · Jest · Cypress.io · Ethers · Taquito · TypeScript · React.js ",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "february 2022 - july 2023 ",
    title: "FullStack developer at STOA",
    desc: "Implementation of the front-end, back-end and indexer of an EVM loan protocol. Integration of services : KYC - Account Abstraction - On/Off ramp",
    competences:
      "WagmiSH · Transak · Sumsub · Express.js · MagicLink · TypeScript · React.js · SQL",
  },
  {
    id: 7,
    category: "experience",
    icon: "icon-briefcase",
    year: "2021 - 2022",
    title: "FullStack developer at Prepar-Vie",
    desc: "Elaboration and implementation of a workflow solution, in autonomy. Collection of needs and expectations, reporting, specification sheets, UI/UX, Bdd schema, Rest API, development of an application, tests",
    competences: "JIRA · Java · Angular · SQL",
  },
];

export default Data;
