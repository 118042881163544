import styled from "styled-components";

export const HackathonsContainer = styled.div`
  display: relative;
`;
export const HackathonsSection = styled.section``;

export const HackathonsTitle = styled.h1`
  margin-bottom: 3.5rem;
  color: ${(props) => props.theme.colors.titleColor};
  font-size: ${(props) => props.theme.font.h1FontSize};
  margin-left: 0.875rem;
`;

export const HackathonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.875rem;

  @media only screen and (max-width: 900px) {
    row-gap: 2rem;
    grid-template-columns: none;
  }
`;

export const globalEthGlobals = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResumeType = styled.div`
  /* display: grid; */
  background-color: ${(props) => props.theme.colors.containerColor};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1.875rem;
  box-shadow: ${(props) => props.theme.shadow};
  height: fit-content;

  width: fit-content;
`;

export const Card = styled.div``;
export const CardContent = styled.div`
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  padding: 22px;
`;

export const CardTitle = styled.h2`
  font-size: 1.5rem;
`;

export const SousTitle = styled.h3`
  color: #374151; /* Equivalent to Tailwind CSS 'text-black-500' (black-500 = #374151) */
  text-transform: uppercase; /* Equivalent to Tailwind CSS 'uppercase' */
  font-size: 0.75rem; /* Equivalent to Tailwind CSS 'text-xs' (xs = 0.75rem) */
  font-weight: normal; /* Equivalent to Tailwind CSS 'font-normal' */
  margin-top: 0.375rem; /* Equivalent to Tailwind CSS 'mt-6' (mt-6 = 0.375rem) */
  margin-bottom: 0.125rem; /* Equivalent to Tailwind CSS 'mb-2' (mb-2 = 0.125rem) */
`;

export const Place = styled.div`
  display: inline-flex;
  overflow: hidden;
  font-weight: 600;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: #40e0d0;
  color: white;
  border: 2px solid black;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 9999px;
  cursor: pointer;
`;

export const PlaceLondon = styled.div`
  display: inline-flex;
  overflow: hidden;
  font-weight: 600;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: #c6c9f6;
  color: black;
  border: 2px solid black;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 9999px;
  cursor: pointer;
`;

export const Bar = styled.hr`
  height: 0.125rem; /* Equivalent to Tailwind CSS 'h-0.5' (h-0.5 = 0.125rem) */
  background-color: black; /* Equivalent to Tailwind CSS 'bg-black' */
  display: block; /* Equivalent to Tailwind CSS 'block' */
  width: 100%; /* Equivalent to Tailwind CSS 'w-full' */
  margin-top: 1.25rem; /* Equivalent to Tailwind CSS 'mt-5' (mt-5 = 1.25rem) */
  margin-bottom: 1.5rem; /* Equivalent to Tailwind CSS 'mb-6' (mb-6 = 1.5rem) */
`;

export const TextWin = styled.h3`
  color: gray; /* Equivalent to Tailwind CSS 'text-black-500' (black-500 = #374151) */
  text-transform: uppercase; /* Equivalent to Tailwind CSS 'uppercase' */
  font-size: 0.75rem; /* Equivalent to Tailwind CSS 'text-xs' (xs = 0.75rem) */
  font-weight: normal; /* Equivalent to Tailwind CSS 'font-normal' */
  margin-top: 1.5rem; /* Equivalent to Tailwind CSS 'mt-6' (mt-6 = 1.5rem) */
  margin-bottom: 0.5rem; /* Equivalent to Tailwind CSS 'mb-2' (mb-2 = 0.5rem) */
`;

export const IMAGE = styled.img`
  width: 2rem; /* Equivalent to Tailwind CSS 'w-8' (w-8 = 2rem) */
  height: 2rem; /* Equivalent to Tailwind CSS 'h-8' (h-8 = 2rem) */
  border: 2px solid black; /* Equivalent to Tailwind CSS 'border-2' and 'border-black' */
  border-radius: 9999px; /* Equivalent to Tailwind CSS 'rounded-full' */
  object-fit: cover; /* Equivalent to Tailwind CSS 'object-cover' */
`;

export const ContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContainerCompanyPrize = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContainerLink = styled.div`
  color: gray;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
`;

export const Link = styled.a`
  padding: 0px 5px;
  text-decoration: none;
  all: unset;
  cursor: pointer;
`;
