import imgSecurd from "../../assets/securd.png";
import imgArtos from "../../assets/imgartos.jpeg";
import cofiImg from "../../assets/cofiImg.jpeg";
import surgeImg from "../../assets/surgeswap.png";
import uniImg from "../../assets/uni.png";

const Projects = [
  {
    id: 11,
    image: surgeImg,
    title: "Historical market indicators",
    category: "Data Analysis",
    link: "https://srg20view.vercel.app/dashboard",
    techs: ["Next", "shadcn ui", "Redis", "wagmiSh", "ethers", "express"],
  },
  {
    id: 6,
    image: uniImg,
    title: "UniMagnifier",
    topic: "Defi trading tracker",
    category: "Data Analysis",
    link: "https://github.com/Marco75116/UniV3Magnifier",
    techs: [
      "Next, Shadcn ui, ethers,express,supabase",
      "wagmiSh",
      "etherscan api",
      "thegraph",
    ],
  },
  {
    id: 6,
    image: imgSecurd,
    title: "Securd",
    topic: "Leverage Yield Farming",
    category: "Dapp Development",
    link: "https://www.youtube.com/watch?v=yeJi6pIHAzs&ab_channel=PyratzLabs",
    techs: ["Next, styled-components, Taquito, ReactQuery, Cypress, Jest"],
  },
  {
    id: 5,
    image: imgArtos,
    title: "Artos",
    topic: "Marketplace",
    category: "Dapp Development",
    link: "https://artos.pyratzlabs.com/",
    techs: [" React, Hasura, Cypress, Taquito, Styled-Components and Redux."],
  },
  {
    id: 9,
    image: cofiImg,
    title: "Cofi",
    topic: "Yield protocol",
    category: "Dapp Development",
    link: "https://app-cofi.money/Swap",
    techs: ["React, Tailwind, WagmiSh, Express, SQL, Mysql, Jest"],
  },

  {
    id: 1,
    image: "https://cdn.morpho.xyz/assets/logos/logo-dark.svg",
    title: "MorphoLabs Analysis",
    category: "Data Analysis",
    link: "https://github.com/Marco75116/Morpholabs",
    techs: ["Web3.py", "Infura", "Chainlink", "Coumpound", "Etherscan"],
  },
  {
    id: 2,
    image: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=023",
    title: "Whale Whatching",
    category: "Data Analysis",
    link: "https://github.com/Marco75116/Whale_watching_MATIC",
    techs: [
      "Python",
      "Sqlite",
      "Angular",
      "FastApi",
      "HighCharts",
      "web3",
      "ApiEtherscan",
      "CryptoCompare",
      "Infura",
      "Postman",
      "Moralis",
    ],
  },
  {
    id: 3,
    image:
      "https://cdn0.scrvt.com/b095ee27d37b3d7b6b150adba9ac6ec8/5677e4f602626f21/0dd4688ca630/v/5d972aa4629a/solidity_overview.png?nowebp=1",
    title: "Blockchain Programming course",
    category: "Blockchain Programming",
    link: "https://github.com/orgs/Blockchain-Programming-2021-2022/repositories",
    techs: ["solidity", "infura", "truffle", "rinkeby", "ethers"],
  },
  ,
  {
    id: 4,
    image:
      "https://img.money.com/2021/12/Investing-5-Cryptocurrency-Predictions-2022.jpg?quality=60&w=1600",
    title: "Cryptocurrency Price Prediction",
    category: "IA",
    link: "https://github.com/Marco75116/-Cryptocurrency_Price_Prediction",
    techs: ["LSTM", "Python", "Tensorflow", "pandas", "matplotlib"],
  },
  ,
  {
    id: 7,
    image:
      "https://human-div.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F5f98445f-4408-4403-b511-faa9cf8f854b%2FHD_publications.jpg?table=block&id=41635242-2fae-45b6-ad5f-66eb8c266aec&spaceId=52572aed-f916-4837-97a0-2a0e1e5ab2a8&width=250&userId=&cache=v2",
    title: "Human Divergence",
    topic: "Mint Page",
    category: "Dapp Development",
    link: "https://github.com/Human-Divergence/MintPage",
    techs: ["Vite, React, TailwindCSS, Wagmi.sh"],
  },
  ,
  {
    id: 8,
    image: "https://upload.wikimedia.org/wikipedia/fr/a/a3/Dofus_emeraude.png",
    title: "Dofus",
    topic: "Bot",
    category: "Others",
    link: "https://github.com/Marco75116/Bot-python-Dofus",
    techs: ["Pytesseract", "Pytautogui", "Pillow"],
  },

  {
    id: 10,
    image:
      "https://web-static.wrike.com/blog/content/uploads/2018/11/How-to-Use-Wrike-as-a-Ticketing-System-1.jpg?av=ec6575d1d154780ce5971ea9a5f0d91d",
    title: "NFT Ticketing",
    category: "Blockchain Programming",
    link: "https://github.com/Marco75116/NFT-Ticketing---Smart-contract",
    techs: ["HardHat", "Solidity", "Mumbai", "rarible", "oppenzeppelin"],
  },
];

export default Projects;
