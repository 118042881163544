import React from "react";
import {
  Bar,
  CardContainer,
  CardContent,
  CardTitle,
  ContainerBottom,
  ContainerCompanyPrize,
  ContainerLink,
  HackathonsContainer,
  HackathonsGrid,
  HackathonsSection,
  HackathonsTitle,
  IMAGE,
  Link,
  Place,
  PlaceLondon,
  ResumeType,
  SousTitle,
  TextWin,
} from "./Hackathons.styled";

const Hackathons = (props) => {
  return (
    <HackathonsContainer>
      <HackathonsSection>
        <HackathonsTitle ref={props.refProp}>Hackathons</HackathonsTitle>
        <HackathonsGrid>
          <CardContainer>
            <ResumeType>
              <iframe
                src="https://www.linkedin.com/embed/feed/update/urn:li:share:6920732514646962177"
                height="746"
                width={window.innerWidth > 900 ? "504" : "100%"}
                frameborder="0"
                allowfullscreen=""
                title="Post intégré"
              ></iframe>
            </ResumeType>
          </CardContainer>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <CardContainer>
              <ResumeType>
                <CardContent>
                  <CardTitle>POSEIDON SWAP</CardTitle>
                  <SousTitle>Created at</SousTitle>
                  <Place>Eth Global Paris</Place>
                  <Bar />
                  <TextWin>Winner of</TextWin>
                  <ContainerBottom>
                    <IMAGE
                      src={
                        "https://storage.googleapis.com/ethglobal-api-production/organizations%2Fif0ri%2Flogo%2F1675803570272_mhT50NcT_400x400.jpeg"
                      }
                    />
                    🌟 1inch — 🥉 Open Track
                  </ContainerBottom>
                </CardContent>
                <ContainerLink>
                  <Link
                    href="https://github.com/BlockchainHackathons/EthGlobal2023"
                    target="_blank"
                  >
                    Github
                  </Link>
                  <Link href="https://youtu.be/pJRJSQUpH8M" target="_blank">
                    Demo
                  </Link>
                </ContainerLink>
              </ResumeType>
            </CardContainer>

            <CardContainer>
              <ResumeType>
                <CardContent>
                  <CardTitle>Adaptum Finance</CardTitle>
                  <SousTitle>Created at</SousTitle>
                  <PlaceLondon>Eth Global London</PlaceLondon>
                  <Bar />
                  <TextWin>Winner of</TextWin>
                  <ContainerBottom>
                    <ContainerCompanyPrize>
                      <IMAGE
                        src={
                          "https://storage.googleapis.com/ethglobal-api-production/organizations%2Ff8ku2%2Fimages%2FChainlink%20Hexagon.png"
                        }
                      />
                      Chainlink - Chainlink CCIP
                    </ContainerCompanyPrize>
                    <ContainerCompanyPrize>
                      <IMAGE
                        src={
                          "https://storage.googleapis.com/ethglobal-api-production/organizations%2Fspp1v%2Fimages%2Farbitrum-shield-1.1-aspect-ratio.png"
                        }
                      />
                      Arbitrum - Qualifying Arbitrum Submissions
                    </ContainerCompanyPrize>
                  </ContainerBottom>
                </CardContent>
                <ContainerLink>
                  <Link
                    href="https://github.com/Magicred-1/AdaptumFi"
                    target="_blank"
                  >
                    Github
                  </Link>
                  <Link
                    href="https://ethglobal.com/showcase/adaptum-finance-g7xxe"
                    target="_blank"
                  >
                    Demo
                  </Link>
                </ContainerLink>
              </ResumeType>
            </CardContainer>
          </div>
        </HackathonsGrid>
      </HackathonsSection>
    </HackathonsContainer>
  );
};

export default Hackathons;
